<template>
  <div class="page-login">
    <div class="logo">
      <router-link to="/">
        <img src="../assets/imgs/logoText.png" />
      </router-link>
    </div>

    <!-- 背景图 -->
    <div class="bannerImg" id="nav">
      <div class="center">
        <h1 class="slogan">即将发布</h1>
        <h4 class="slogan_en">敬请期待...</h4>
        <div class="desc">
          <p>COMING SOON</p>
          <p>STAY TUNED...</p>
        </div>
        <div class="button hidden-sm-and-up">
          <!-- <router-link to="/register">
                    <span class="register">注册</span>
                  </router-link> -->
          <router-link to="/">
            <el-button type="primary" class="back">点击返回</el-button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import VueCookies from "vue-cookies";
// import { httpPost, httpPostIsAnonymous } from "../api/httpService";
export default {
  data() {
    return {};
  },

  methods: {}
};
</script>

<style lang="scss" scoped>
.page-login {
  .logo {
    width: 20%;
    height: 60px;
    line-height: 60px;
    float: left;
    padding: 0px 10px 0px;
    img {
      display: inline-block;
      width: 98px;
      height: 35px;
      margin-left: 65px;
      vertical-align: middle;
    }
  }

  // 背景图
  .bannerImg {
    width: 100%;
    color: #188b99;
    text-align: center;
    background: url("../assets/imgs/login-bg.jpg");
    background-size: cover;
    // background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 790px;
    .center {
      padding: 200px 0;
      .slogan {
        text-align: left;
        font-size: 60px;
        color: #0f5962;
        font-family: SourceHanSansSC-regular;
        margin-top: 20px;
        margin-left: 200px;
        padding: 30px 15px 10px;
      }
      .slogan_en {
        text-align: left;
        color: #0f5962;
        font-family: SourceHanSansSC-regular;
        font-size: 60px;
        margin-left: 200px;
        margin-top: 10px;
        padding: 0 15px 30px;
      }
      .desc {
        font-size: 20px;
        text-align: left;
        font-weight: bold;
        color: #0f5962;
        line-height: 30px;
        margin-left: 200px;
        margin-bottom: 15px;
        padding: 0 15px;
        span {
          color: #188b99;
          margin-right: 5px;
          font-family: SourceHanSansSC-regular;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .page-login {
    .logo {
      width: 20%;
      height: 60px;
      line-height: 60px;
      float: left;
      padding: 0px 10px 0px;
      img {
        display: inline-block;
        width: 70px;
        height: 25px;
        margin-left: 15px;
        vertical-align: middle;
      }
    }

    // 背景图
    .bannerImg {
      width: 100%;
      color: #188b99;
      text-align: center;
      background: url("../assets/imgs/phone-login-bg.jpg");
      background-size: cover;
      // background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center center;
      min-height: 790px;
      .center {
        padding: 80px 0;
        .slogan {
          text-align: left;
          font-size: 40px;
          color: #0f5962;
          font-family: SourceHanSansSC-regular;
          margin-top: 20px;
          margin-left: 15px;
          padding: 10px 15px 10px;
        }
        .slogan_en {
          text-align: left;
          color: #0f5962;
          font-family: SourceHanSansSC-regular;
          font-size: 40px;
          margin-left: 15px;
          margin-top: 5px;
          padding: 0 15px 10px;
        }
        .desc {
          font-size: 18px;
          text-align: left;
          font-weight: bold;
          color: #0f5962;
          margin-left: 15px;
          margin-bottom: 15px;
          padding: 0 15px;
          span {
            color: #188b99;
            margin-right: 5px;
            font-family: SourceHanSansSC-regular;
          }
        }
        .button {
          text-align: left;
          margin-left: 32px;
          margin-top: 15px;
          .back {
            background-color: rgba(24, 139, 153, 1);
            border-color: rgba(24, 139, 153, 1);
            color: rgba(255, 255, 255, 1);
            font-family: Roboto;
          }
        }
      }
    }
  }
}
</style>
